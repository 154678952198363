.test-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #fff;
  align-items: center;
  overflow-x: hidden;
}
.test-title-wrapper {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviews-wrapper {
  display: flex;
  gap: 45px;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  flex-wrap: wrap;
}
.review {
  border: 1px solid #272727;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 3px rgba(46, 46, 46, 0.15);
  height: 225px;
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.r-top {
  height: 40%;
}
.r-body {
  height: 60%;
}
.r-name {
  font-family: "Medium";
}
.r-title {
  font-family: "Light";
  font-size: 12px;
  padding-top: 5px;
}
.r-txt {
  font-family: "Light";
  font-size: 14px;
  line-height: 24px;
}
.extra {
  border: 1px solid #1a1a1a;
}

@media only screen and (max-width: 1400px) {
  .review {
    width: 30%;
  }
}
@media only screen and (max-width: 1150px) {
  .test-wrapper {
    height: auto;
  }
  .test-title-wrapper {
    padding: 30px;
  }
  .reviews-wrapper {
    margin-top: 90px;
    flex-direction: column;
  }
  .review {
    width: 50%;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .review {
    width: 70%;
  }
}

@media only screen and (max-width: 550px) {
  .review {
    width: 90%;
  }
}
