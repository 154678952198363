.changelog-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  width: 800px;
  padding-top: 225px;
  padding-bottom: 0px;
  color: #fafafa;
}

.changelog-title {
  font-family: "Medium";
  font-weight: 400;
  font-size: 32px;
}

.changelog-sub {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 30px;
  color: #939393;
}

/* Container for the date and content */
.changelog-container {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
  gap: 20px;
  padding: 60px 0px;
}

.changelog-container:not(:first-of-type) {
  border-top: 1px solid #27272a;
}

/* Sticky date on the left */
.changelog-date {
  flex: 1;
  position: sticky;
  top: calc(190px + 0px);
  align-self: flex-start;
  font-size: 14px;
  color: gray;
}

/* Content on the right */
.changelog-content {
  flex: 3;
  font-size: 18px;
  line-height: 1.6;
}

.changelog-content img {
  max-width: 100%;

  margin: 0 auto;
}

.changelog-big-title {
  font-size: 22px;
  font-family: "Medium";
  font-weight: 400;

  color: rgb(235, 235, 235);
  margin-bottom: 10px;
}

.changelog-mini-title {
  font-size: 18px;
  color: rgb(220, 220, 220);
  font-family: "Medium";
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 15px;
}

.changelog-content-text {
  font-size: 14px;
  color: rgb(205, 205, 205);
  font-family: "Medium";
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 10px;
}

.list-margin {
  margin-left: 20px;
}

.name-sign {
  font-size: 14px;
  font-weight: bold;
}

li {
  margin: 10px 0px;
}
strong {
  font-family: "Medium";
  font-weight: 400;
  color: #fff;
}

/* Optional: responsive styling */
@media (max-width: 800px) {
  .changelog-wrapper {
    width: auto;
    padding: 30px;
    padding-top: 120px;
  }
  .changelog-container {
    flex-direction: column;
  }
  .changelog-date {
    position: relative;
    top: 0;
  }
}
