.feature-page-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px;
  overflow: hidden;
}

.f-text-wrapper {
  width: 55%;
}

.f-title {
  font-size: 64px;
  color: #fff;
  font-family: "Regular";
  letter-spacing: -3px;
  line-height: 70px;
}
.f-sub-txt {
  font-family: "Regular";
  color: #eeeeee;
  width: 65%;
  padding-top: 15px;
  line-height: 26px;
}

.f-image-wrapper {
  width: 45%;
  width: auto;
}

.f-image-wrapper img {
  height: 550px;
}
.problems-img img {
  height: 450px;
}
.f-space {
  flex: 1;
}
.course-img img {
  height: 325px;
}
.course-sub-txt {
  width: 80%;
}

@media only screen and (max-width: 1700px) {
  .feature-page-wrapper {
    padding: 100px;
  }
  .course-img img {
    height: 250px;
  }
}

@media only screen and (max-width: 1500px) {
  .f-image-wrapper img {
    height: 400px;
  }
  .f-sub-txt {
    width: 80%;
  }
}

@media only screen and (max-width: 1200px) {
  .f-image-wrapper img {
    height: 350px;
  }
  .f-title {
    font-size: 52px;
  }
  .f-sub-txt {
    width: 80%;
    font-size: 14px;
  }
  .course-img img {
    height: 450px;
  }
}

@media only screen and (max-width: 1200px) {
  .feature-page-wrapper {
    flex-direction: column;
  }
  .f-text-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-bottom: 60px;
  }
  .f-sub-txt {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .f-title {
    text-align: center;
  }
  .f-image-wrapper img {
    height: 450px;
  }
}

@media only screen and (max-width: 1000px) {
  .course-img img {
    height: 325px;
  }
}

@media only screen and (max-width: 800px) {
  .problems-img img {
    height: 300px;
  }
  .f-space {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .feature-page-wrapper {
    padding: 60px;
  }
  .f-text-wrapper {
    width: 100%;
  }
  .f-image-wrapper img {
    height: 350px;
  }
  .flash-img img {
    height: 450px;
  }
  .f-space {
    display: none;
  }
  .f-title {
    font-size: 42px;
    letter-spacing: -2px;
  }
  .problems-img img {
    height: 300px;
  }
  .course-img img {
    height: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .feature-page-wrapper {
    padding: 30px;
  }
  .f-title {
    font-size: 32px;
    line-height: 48px;
  }
  .f-sub-txt {
    font-size: 14px;
    width: 100%;
    line-height: 22px;
  }
  .f-image-wrapper img {
    height: 250px;
  }
  .flash-img img {
    height: 350px;
  }
  .problems-img img {
    height: 200px;
  }
  .course-img img {
    height: 150px;
  }
}
