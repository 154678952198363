.legal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 600px;
  padding-top: 150px;
  padding-bottom: 150px;
  color: #fafafa;
}

.legal-title {
  font-family: "Regular";
  letter-spacing: 1px !important;
  padding-bottom: 15px;
}
.legal-date {
  margin-bottom: 20px;
}
.bold {
  font-family: "SemiBold";
}

.title {
  font-family: "SemiBold";
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.space {
  margin-bottom: 20px;
}

.content-wrapper {
  line-height: 27px;
}

@media only screen and (max-width: 1650px) {
  .legal-wrapper {
    margin: 0px 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .legal-wrapper {
    margin: 0px 350px;
  }
}

@media only screen and (max-width: 1100px) {
  .legal-wrapper {
    margin: 0px 300px;
  }
}

@media only screen and (max-width: 1000px) {
  .legal-wrapper {
    margin: 0px 200px;
  }
}

@media only screen and (max-width: 800px) {
  .legal-wrapper {
    margin: 0px 150px;
  }
}
@media only screen and (max-width: 650px) {
  .legal-wrapper {
    margin: 0px 60px;
  }
}

@media only screen and (max-width: 500px) {
  .legal-wrapper {
    margin: 0px 30px;
  }
}
