.join-wrapper {
  height: 120vh;
  width: 100%;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Regular";
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
.j-page {
  overflow-x: hidden;
}
.join-main-txt {
  margin-top: 350px;
  font-size: 72px;
  font-family: "Regular";
  letter-spacing: -2px;
}
.join-sub {
  font-family: "Regular";
  color: #eee;
  padding-top: 20px;
}
span {
  font-family: "Medium";
}

.alert-wrapper {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.15s ease-in-out;
}
.alert-container {
  height: 150px;
  width: 500px;
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: #0f0f0f;
  padding: 30px;
}
.alert-txt {
  font-size: 16px;
  font-family: "Medium";
}
.close-btn {
  font-family: "Regular";
  height: 40px;
  width: 75px;
  background-color: #0f0f0f;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.15s ease-in-out;
}
.close-btn:hover {
  cursor: pointer;
  background-color: #363636;
}
.footer-wrapper {
  height: 250px;
  width: 60%;
  border-radius: 10px;
  background-color: #1a1a1a;
  display: flex;
  padding: 30px;
  padding-right: 0px;
}
.footer-col {
  padding-right: 30px;
}
.footer-header {
  font-family: "Medium";
  font-size: 14px;
  padding-bottom: 10px;
}
.footer-link {
  font-family: "Light";
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.1s ease-in-out;
}
.footer-link:hover {
  cursor: pointer;
  opacity: 0.6;
}
.copyright-txt {
  padding: 45px;
  font-family: "Light";
  font-size: 12px;
}
.j-space {
  flex: 1;
}
.join-btn {
  height: 45px;
}

@media only screen and (max-width: 1350px) {
  .join-main-txt {
    font-size: 64px;
  }
}

@media only screen and (max-width: 1000px) {
  .join-main-txt {
    font-size: 54px;
    margin-top: 200px;
  }
  .join-sub {
    font-size: 14px;
  }
  .footer-wrapper {
    width: 75%;
    height: 225px;
  }
}
@media only screen and (max-width: 802px) {
  .alert-wrapper {
    width: 100vw;
  }
  .alert-container {
    width: 350px;
  }
  .alert-txt {
    font-size: 14px;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .j-page {
    width: 100vw;

    padding: 60px;
  }
  .join-sub,
  .join-main-txt {
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .footer-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .join-main-txt {
    font-size: 36px;
  }
}

@media only screen and (max-width: 530px) {
  .footer-wrapper {
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-top: 15px;
    padding-left: 0px;
    margin-top: 120px;
  }
  .join-wrapper {
    height: auto;
  }
  .footer-col {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-header {
    padding-top: 15px;
  }
  .j-page {
    padding: 30px;
  }
}
