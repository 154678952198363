.integrations-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.integrations-title {
  font-family: "Regular";
  font-size: 52px;
  letter-spacing: -3px;
  color: #fff;
}
.integration-container {
  margin-top: 90px;
  width: 70%;
  height: 500px;
  background-color: #edf0fd;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 75px;
  color: #0f0f0f;
}

.int-top-txt {
  font-size: 10px;
  padding-bottom: 10px;
  font-family: "SemiBold";
}
.int-heading {
  font-size: 36px;
  font-family: "Medium";
  width: 65%;
  letter-spacing: -1px;
}
.int-sub-txt {
  font-size: 14px;
  padding-top: 15px;
  width: 65%;
}
.int-right {
  display: flex;
  flex-direction: column;
}
.int {
  background-color: #e7eafb;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.row {
  display: flex;
  gap: 45px;
}
.r-one {
  margin-bottom: 45px;
}

@media only screen and (max-width: 1500px) {
  .integration-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1300px) {
  .int-heading {
    width: 80%;
  }
}

@media only screen and (max-width: 1150px) {
  .integration-container {
    flex-direction: column;
  }
  .int-top-txt,
  .int-heading,
  .int-sub-txt {
    text-align: center;
    width: 100%;
  }
  .int-right {
    padding-top: 30px;
  }
  .int {
    width: 70px;
    height: 70px;
  }
  .int img {
    height: 35px;
  }
}

@media only screen and (max-width: 1300px) {
  .int-heading {
    font-size: 32px;
  }
  .integration-container {
    padding: 30px;
    justify-content: center;
  }
  .int-right {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 550px) {
  .int-right {
    flex-direction: row;
  }
  .row {
    flex-direction: column;
  }
  .r-one {
    margin-right: 30px;
  }
  .integration-container {
    height: auto;
    width: 80%;
  }
  .int-heading {
    font-size: 20px;
  }
}
