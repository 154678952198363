.pricing-container {
  height: 140vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: "Regular";
}
.pricing-boxes-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
}

.p-top-wrapper {
  display: flex;
  gap: 25px;
}
.pricing-box {
  border: 1px solid #3f3f3f;
  height: 550px;
  width: 300px;
  border-radius: 15px;
  background-color: #131313;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.pricing-box-title {
  font-weight: 500;
  font-family: "Medium";
}
.pricing-box-sub {
  font-size: 12px;
  color: #9c9c9c;
  padding-top: 5px;
  margin-bottom: 25px;
}
.price {
  font-size: 42px;
  font-family: "Medium";
  padding-bottom: 30px;
  border-bottom: 1px solid #3f3f3f;
  margin-bottom: 20px;
}
.requirement {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.requirement-txt {
  font-size: 14px;
  padding-left: 10px;
  font-family: "Light";
}
.and {
  font-size: 13px;
  color: #9c9c9c;
  margin-top: 10px;
}
.pro-txt {
  font-size: 13px;
  padding-bottom: 10px;
  font-family: "Light";
}
.p-bottom-wrapper {
  display: flex;
  width: 625px;
  margin-top: 25px;
}
.limited-offer-box {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  height: 190px;
  color: #0f0f0f;
  padding: 20px;
}
.limited-txt {
  font-size: 10px;
  font-family: "SemiBold";
  margin-bottom: 5px;
}
.limited-content {
  margin-top: 20px;
  font-size: 14px;
  padding-right: 30px;
}
.life {
  font-family: "SemiBold";
}
.limited-left {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.limited-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.limited-price {
  font-size: 36px;
  font-family: "Medium";
}
.smaller {
  font-size: 14px;
}

.spots-left {
  font-size: 12px;
}
.reserve-btn {
  height: 45px;
  width: 125px;
  border-radius: 10px;
  border: none;
  background-color: #0f0f0f;
  color: #fff;
  font-family: "Medium";
  transition: all 0.12s ease-in-out;
}
.reserve-btn:hover {
  cursor: pointer;
  background-color: #1d1d1d;
}
.buy-btn {
  height: 50px;
  border-radius: 15px;
  background-color: #1d1d1d;
  border: 1px solid #3f3f3f;
  font-family: "Regular";
  color: #9c9c9c;
  transition: all 0.12s ease-in-out;
}
.buy-btn:hover {
  cursor: pointer;
  background-color: #0f0f0f;
}
.p-space {
  flex: 1;
}

@media only screen and (max-width: 800px) {
  .pricing-container {
    height: auto;
  }
  .p-top-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .pricing-boxes-wrapper {
    width: 80%;
  }
  .pricing-box,
  .limited-offer-box {
    width: 100%;
  }
  .p-bottom-wrapper {
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 560px) {
  .limited-offer-box {
    flex-direction: column;
    height: auto;
  }
  .limited-left,
  .limited-right {
    width: 100%;
    align-items: center;
  }
  .limited-content {
    text-align: center;
    padding-right: 0px;
  }
  .limited-right {
    flex-direction: row;
    align-items: flex-end;
    margin-top: 15px;
  }
  .spots-left {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .price {
    font-size: 32px;
  }
  .pricing-boxes-wrapper {
    width: 90%;
  }
  .limited-right {
    flex-direction: column;
    align-items: center;
  }
  .spots-left {
    display: block;
    margin-bottom: 15px;
  }
}
