.home-wrapper {
  height: 130vh;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Regular";
}
.alert-wrapper {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.15s ease-in-out;
}
.alert-container {
  height: 150px;
  width: 500px;
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: #0f0f0f;
  padding: 30px;
}
.alert-txt {
  font-size: 16px;
  font-family: "Medium";
}
.close-btn {
  font-family: "Regular";
  height: 40px;
  width: 75px;
  background-color: #0f0f0f;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.15s ease-in-out;
}
.close-btn:hover {
  cursor: pointer;
  background-color: #363636;
}
.submitted-wrapper {
  width: 300px;
  height: 45px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  color: #0f0f0f;
  transition: all 0.15s ease-in-out;
}
.submitted-txt {
  font-family: "Medium";
  font-size: 14px;
}
.nav-link-wrapper {
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: center;
}
.nav-txt {
  font-size: 15px;
  font-family: "Regular";
  transition: 0.1s ease-in-out;
}
.features:hover {
  opacity: 0.5;
  cursor: pointer;
}
.logo-wrapper {
  display: flex;
  align-items: center;
}
.logo-container {
  height: 30px;
  transition: 0.12s ease-in-out;
}
.logo-container:hover {
  opacity: 0.6;
  cursor: pointer;
}
.logo {
  height: 100%;
}
.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sign-up-btn {
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: transparent;
  color: #fff;
  font-family: "Medium";
  transition: 0.1s ease-in-out;
}
.sign-up-btn:hover {
  background-color: #fafafa;
  color: #0f0f0f;
  font-family: "Medium";
  cursor: pointer;
}
.login-btn {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: transparent;
  color: #0f0f0f;
  background-color: #fafafa;
  font-family: "Medium";
  border: none;
  transition: 0.1s ease-in-out;
}
.login-btn:hover {
  cursor: pointer;
  background-color: #c7c7c7;
}

.landing-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140vh;
}
.left {
  width: 55%;
  height: 55%;
  padding-left: 120px;
  display: flex;
  flex-direction: column;
}
.right {
  width: 45%;
  height: 100%;
  overflow: hidden;
}
.right img {
  height: 800px;
  box-shadow: 0px 0px 15px 5px #161616;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  top: 200px;
  margin-left: 20px;
  z-index: 0;
}
.landing-sub {
  font-size: 16px;
  padding-top: 20px;
  width: 60%;
  font-family: "Regular";
}

.landing-main,
.mobile-landing {
  font-size: 64px;

  font-family: "Regular";
  font-weight: 400;
  letter-spacing: -2px;
  margin: 0;
}
.waitlist-input {
  margin-top: 45px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.email-in::placeholder {
  color: #fff;
  font-family: "Light";
  font-size: 13px;
}

.email-in {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  font-family: "Regular";
  height: 45px;
  width: 100%;
  padding-left: 10px;
  margin-right: 15px;
  color: #fff;
}
.join-btn {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-family: "Regular";
  transition: 0.1s ease-in-out;
  border: 1px solid #fff;
  background-color: #fff;
  color: #0f0f0f;
}
.landing-btn {
  margin-top: 30px;
  width: 150px;
  border-radius: 15px;
  font-family: "Medium";
}
.join-btn:hover {
  color: #fff;
  background-color: #0f0f0f;
  cursor: pointer;
}
.h-space {
  flex: 1;
}
.how-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px;
}
.how-txt {
  text-align: center;
  font-family: "Light";
  font-size: 13px;
}
.mobile-landing {
  display: none;
}
@media only screen and (max-width: 1510px) {
  .left {
    width: 65%;
  }
  .right {
    width: 35%;
  }
  .right img {
    height: 800px;
  }
}
@media only screen and (max-width: 1400px) {
  .landing-main {
    font-size: 56px;
  }
  .landing-sub {
    font-size: 16px;
    width: 75%;
  }
}
@media only screen and (max-width: 1150px) {
  .left {
    width: 60%;
  }
  .landing-main {
    font-size: 48px;
  }
  .landing-sub {
    font-size: 14px;
    width: 85%;
  }
  .right {
    width: 40%;
  }
  .right img {
    height: 650px;
  }
}

@media only screen and (max-width: 1100px) {
  .nav-link-wrapper {
    display: none;
  }
  .nav-wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .landing-main {
    font-size: 42px;
  }
  .left {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 850px) {
  .landing-section {
    flex-direction: column;
    padding-top: 200px;
  }
  .home-wrapper {
    height: auto;
  }
  .how-wrapper {
    display: none;
  }
  .h-space {
    display: none;
  }
  .left {
    width: 100%;
    height: 35%;
  }
  .right {
    width: 100%;
    height: 65%;
  }
  .right img {
    height: 600px;
    top: 0;
    margin-left: 60px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 840px) {
  .landing-main {
    width: 65%;
  }
}

@media only screen and (max-width: 800px) {
  .nav-wrapper {
    justify-content: center;
  }
  .button-wrapper {
    display: none;
  }
  .landing-main {
    display: none;
  }
  .mobile-landing {
    display: block;
    font-size: 48px;
    text-align: center;
  }
  .left {
    padding-right: 60px;
  }
  .landing-sub {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .waitlist-input {
    display: flex;
    justify-content: center;
  }
  .landing-btn {
    align-self: center;
  }
}
@media only screen and (max-width: 660px) {
  .landing-sub {
    font-size: 16px;
  }
  .mobile-landing {
    font-size: 36px;
  }
  .left {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .landing-sub {
    font-size: 16px;
  }
  .landing-main {
    width: 75%;
  }
  .landing-main {
    font-size: 36px;
  }
  .right img {
    height: 600px;
  }
  .landing-section {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 490px) {
  .right img {
    height: 450px;
  }
}

@media only screen and (max-width: 420px) {
  .landing-sub {
    font-size: 14px;
  }
  .mobile-landing {
    font-size: 32px;
  }
}

@media only screen and (max-width: 400px) {
  .right img {
    height: 400px;
  }
  .home-wrapper {
    height: 120vh;
  }
}
