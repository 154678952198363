.faq {
  color: #fff;
  font-family: Regular;
}

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
  margin-top: 60px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #fff;
  transition: box-shadow 0.3s ease;
}

.faq:hover {
  cursor: pointer;
}

.faq:hover > .faq-question {
  opacity: 0.6;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 16px;
  font-family: "Medium";
  padding-right: 80px;
  transition: all 0.3s ease; /* Increased transition time */
  padding-bottom: 10px;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease; /* Added transition for rotation */
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.2s ease; /* Added transitions for opacity and max-height */
  font-family: "Light";
  font-size: 14px;
  line-height: 22px;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
  transform: rotate(180deg); /* Rotate the pseudo-element for the open state */
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
