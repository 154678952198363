.features-wrapper {
  height: 100vh;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Regular";
}

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
}

.features-txt {
  font-size: 52px;
  text-align: center;
  color: #fff;
  font-family: "Regular";
  letter-spacing: -1px;
  margin: 0;
  padding-top: 60px;
}
.features-sub {
  font-family: "Regular";
  text-align: center;
  color: #eee;
  padding-top: 20px;
}
.features-box-container {
  display: flex;
  margin-top: 100px;
  padding: 40px 60px;
  justify-content: space-between;
  gap: 30px;
}

.feature-box {
  width: 450px;
  height: 175px;
  border-radius: 7px;
  padding: 25px;
}
.white {
  color: #0f0f0f;
}
.white-box {
  background-color: #fff;
}

.title-wrapper {
  display: flex;
  height: 20%;
  align-items: center;
}
.feature-title {
  font-size: 14px;
  font-family: "Medium";
  padding-left: 10px;
}
.feature-txt {
  height: 80%;
  font-size: 14px;
  margin: 0;
  padding-top: 15px;
}

@media only screen and (max-width: 1550px) {
  .feature-box {
    width: 375px;
  }
  .feature-title,
  .feature-txt {
    font-size: 13px;
  }
  .title-wrapper img {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (max-width: 1325px) {
  .features-wrapper {
    height: auto;
  }
  .features-box-container {
    flex-direction: column;
  }
  .features-box {
    align-items: center;
    justify-content: center;
  }
  .feature-box {
    width: 500px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .features-txt {
    font-size: 42px;
  }
  .features-sub {
    width: 55%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  .features-txt {
    font-size: 32px;
  }
  .features-sub {
    width: 65%;
    font-size: 14px;
  }
  .features-box-container {
    padding: 0;
  }
}

@media only screen and (max-width: 550px) {
  .feature-box {
    width: 400px;
  }
  .title-wrapper {
    justify-content: center;
  }
  .feature-txt {
    text-align: center;
  }
}

@media only screen and (max-width: 550px) {
  .feature-box {
    width: 350px;
  }
}

@media only screen and (max-width: 450px) {
  .feature-box {
    width: 320px;
    height: 200px;
  }
  .features-txt {
    font-size: 26px;
  }
  .features-sub {
    width: 85%;
    font-size: 14px;
  }
}
