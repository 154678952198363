* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.nav-wrapper {
  background-color: #0f0f0f;
  color: #fff;

  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  z-index: 10;
}

.alpha-banner {
  position: fixed; /* Change display: fixed to position: fixed */
  top: 0;
  left: 0;
  min-height: 60px;
  z-index: 100;
  width: 100vw;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #27272a;
}

.changelog-text {
  color: #fff;
  font-family: "SemiBold";
  text-decoration: none;
}

.banner-txt {
  font-size: 14px;
  font-family: "Regular";
  padding: 15px;
  text-align: center;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  background-color: #0f0f0f;
  padding: 25px 120px;
  height: 90px;
  height: 90px;
}

@media only screen and (max-width: 800px) {
  .alpha-banner {
    display: none;
  }
  .nav-container {
    margin: 0;
    justify-content: center;
  }
}

.home-wrapper,
.features-wrapper,
.join-wrapper {
  height: 33%;
}
.light {
  font-family: "Light";
}
.app {
  height: 300vh;
}
.logo-txt {
  font-family: "SemiBold";
  font-size: 18px;
}

.socials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
}
.ig {
  transition: all 0.1s ease-in-out;
}
.ig:hover {
  cursor: pointer;
  opacity: 0.5;
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("GeneralSansRegular"),
    url("../src/assets/fonts/GeneralSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("GeneralSansMedium"),
    url("../src/assets/fonts/GeneralSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("GeneralSansLight"),
    url("../src/assets/fonts/GeneralSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraLight"; /*Can be any text*/
  src: local("GeneralSansExtralight"),
    url("../src/assets/fonts/GeneralSans-Extralight.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold"; /*Can be any text*/
  src: local("GeneralSansSemibold"),
    url("../src/assets/fonts/GeneralSans-Semibold.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #0f0f0f;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #727272;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}
